import React from "react";
import * as S from "./StyledWaiting";
import { CircularProgress, Typography } from "@mui/material";

interface Props {}

const Component = (props: Props) => {
  return (
    <S.Container>
      <CircularProgress />
      <Typography sx={{ marginLeft: "10px" }}>
        Waiting for payload...
      </Typography>
    </S.Container>
  );
};

export default Component;
