import React from "react";
import * as S from "./StyledDashboard";
import { Fade } from "@mui/material";
import WebsiteUnderConstruction from "../../widgets/WebsiteUnderConstruction/WebsiteUnderConstruction";

const Component = () => {
  return (
    <Fade in appear timeout={500}>
      <S.Container>
        <WebsiteUnderConstruction pageName="dasboard" />
      </S.Container>
    </Fade>
  );
};

export default Component;
