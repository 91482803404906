import React, { ReactNode } from "react";
import * as S from "./StyledItemViewer";

interface Props {
  children: JSX.Element | ReactNode;
  styles: {} | undefined;
}

const Component = (props: Props) => {
  const { children, styles } = props;

  return <S.Container sx={styles}>{children}</S.Container>;
};

export default Component;
