import styled from "@emotion/styled";
import { Box } from "@mui/material";

interface Props {}

const Container = styled(Box)<Props>`
  & .MuiPaper-root {
    padding: 0;
    & .MuiList-root {
    }
  }
`;

export { Container };
