import React, { useContext } from "react";
import { AppContext } from "../../contexts/AppContext";
import { SnackBarContext } from "../../contexts/SnackBarContext";
import * as S from "./StyledQueue";
import * as T from "../../assets/themes";
import * as I from "../../assets/types";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  TextField,
  MenuItem,
  IconButton,
  Chip,
  Tooltip,
  Fade,
} from "@mui/material";
import {
  ContentCopy as ContentCopyIcon,
  AccessTime as AccessTimeIcon,
  Clear as ClearIcon,
} from "@mui/icons-material";
import ItemViewer from "../../widgets/ItemViewer/ItemViewer";

interface Props {}

const Component = (props: Props) => {
  //contexts:
  const appCtx = useContext(AppContext);
  const snackCtx = useContext(SnackBarContext);

  const copyQueueUrlToClipboard = (queue: I.Queues) => {
    navigator.clipboard
      .writeText(queue.url)
      .then(() => {
        appCtx?.updateQueueStatus(queue.name, "COMPLETED");
        snackCtx?.changeMessage(
          "the url was successfully copied to the clipboard",
        );
        snackCtx?.changeSeverity("success");
        snackCtx?.handleOpen();
      })
      .catch(() => {
        snackCtx?.changeMessage(
          "something went wrong, the url couldn't be copied to the clipboard",
        );
        snackCtx?.changeSeverity("warning");
        snackCtx?.handleOpen();
      });
  };

  const updateQueueStatusToExpired = (queue: I.Queues) => {
    try {
      appCtx?.updateQueueStatus(queue.name, "EXPIRED");
      (() => {
        snackCtx?.changeMessage("the queue was successfully discarded");
        snackCtx?.changeSeverity("success");
        snackCtx?.handleOpen();
      })();
    } catch (error) {
      snackCtx?.changeMessage(
        "something went wrong, the queue couldn't be discarded",
      );
      snackCtx?.changeSeverity("warning");
      snackCtx?.handleOpen();
    }
  };

  const openQueueUrl = (queue: I.Queues) => {
    try {
      const url = queue.url;
      window.open(url, "_blank");
      appCtx?.updateQueueStatus(queue.name, "EXPIRED");
    } catch (error) {
      snackCtx?.changeMessage(
        "something went wrong, the queue couldn't be opened in",
      );
      snackCtx?.changeSeverity("warning");
      snackCtx?.handleOpen();
    }
  };

  return (
    <Fade in appear timeout={500}>
      <S.Container>
        <S.Wrapper>
          <S.Header>
            <Typography variant="h6">queues</Typography>
            <TextField
              variant="outlined"
              id="queue-filter"
              aria-label="queue filter"
              size="small"
              label="filter by session"
              select
              inputProps={{
                session: appCtx?.selectedSession?.name || "",
              }}
              value={appCtx?.selectedSession?.description || ""}
              onChange={(e) => appCtx?.changeSelectedSession(e.target.value)}
            >
              <MenuItem
                value=""
                sx={{
                  height: "25px",
                  padding: "16px 6px",
                }}
              >
                <span></span>
              </MenuItem>
              {appCtx?.availableSessions?.map((option, key) => (
                <MenuItem key={key} value={option.description}>
                  {option.description}
                </MenuItem>
              ))}
            </TextField>
          </S.Header>

          {appCtx?.sessionQueues?.length === 0 ? (
            <ItemViewer styles={T.defaultStyles}>
              <Typography>there are not queues available yet</Typography>
            </ItemViewer>
          ) : (
            <>
              <S.TableContainer>
                <Table
                  stickyHeader
                  sx={{ minWidth: 450 }}
                  aria-label="available-records"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <Typography>name</Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography>time left</Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography>status</Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography>actions</Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {appCtx?.sessionQueues?.map((row) => (
                      <TableRow
                        key={row.name}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          <Typography
                            variant="h6"
                            sx={{
                              cursor: "pointer",
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                            onClick={() => openQueueUrl(row)}
                          >
                            {row.name}
                          </Typography>
                        </TableCell>
                        <TableCell align="left">
                          <Chip
                            icon={<AccessTimeIcon />}
                            label={row.time_left}
                          />
                        </TableCell>
                        <TableCell align="left">
                          <Chip
                            label={row.status}
                            color={
                              row.status === "IN_PROGRESS"
                                ? "primary"
                                : "warning"
                            }
                            sx={{
                              "& > span": {
                                color: "white",
                              },
                            }}
                          />
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{
                            "& > :last-child": {
                              margin: "0 5px",
                            },
                          }}
                        >
                          <Tooltip title="copy url">
                            <IconButton
                              onClick={() => copyQueueUrlToClipboard(row)}
                            >
                              <ContentCopyIcon sx={{ fontSize: 25 }} />
                            </IconButton>
                          </Tooltip>

                          <Tooltip title="discard queue">
                            <IconButton
                              onClick={() => updateQueueStatusToExpired(row)}
                            >
                              <ClearIcon sx={{ fontSize: 25 }} />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </S.TableContainer>
            </>
          )}
        </S.Wrapper>
      </S.Container>
    </Fade>
  );
};

export default Component;
