import React, { useContext, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import * as S from "./StyledHome";
import NavBar from "../../components/NavBar/NavBar";
import SideBar from "../../components/SideBar/SideBar";
import Main from "../../components/Main/Main";
import { Box } from "@mui/material";
import { AppContext } from "../../contexts/AppContext";

const Component = () => {
  const appCtx = useContext(AppContext);

  const { module } = useParams();
  const navigate = useNavigate();

  function handleNavigate(path?: string) {
    path ? navigate(`/${path}`) : navigate(`/`);
  }

  useEffect(() => {
    try {
      appCtx?.changeActiveModule(module);
    } catch (error) {
      console.error(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [module]);

  return (
    <S.Container>
      <NavBar handleNavigate={handleNavigate} />
      <Box>
        <SideBar
          module={module}
          modules={appCtx?.availableModules}
          handleNavigate={handleNavigate}
        />
        <Main module={module} handleNavigate={handleNavigate} />
      </Box>
    </S.Container>
  );
};

export default Component;
