import React, { useContext } from "react";
import { AppContext } from "../../contexts/AppContext";
import * as S from "./StyledMain";
import Waiting from "../../widgets/Waiting/Waiting";
import Dashboard from "../Dashboard/Dashboard";
import Stadium from "../Stadium/Stadium";
import Session from "../Session/Session";
import Queue from "../Queue/Queue";
import Cart from "../Cart/Cart";
import Ticket from "../Ticket/Ticket";
import Setting from "../Setting/Setting";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

interface Props {
  module: string | undefined;
  handleNavigate: (path: string) => void;
}

const Component = (props: Props) => {
  const { module } = props;
  const appCtx = useContext(AppContext);

  const theme = useTheme();
  const smDisplay = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <S.Container openDrawer={appCtx?.openDrawer} smDisplay={smDisplay}>
      {module === "dashboards" ? (
        <Dashboard />
      ) : module === "stadiums" ? (
        <Stadium />
      ) : module === "sessions" ? (
        <Session />
      ) : module === "queues" ? (
        <Queue />
      ) : module === "carts" ? (
        <Cart />
      ) : module === "tickets" ? (
        <Ticket />
      ) : module === "settings" ? (
        <Setting />
      ) : (
        <Waiting />
      )}
    </S.Container>
  );
};

export default Component;
