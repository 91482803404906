import styled from "@emotion/styled";
import * as T from "../../assets/themes";

interface Props {}

const Container = styled.section<Props>`
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: auto;
  // background-color: ${T.colors.nonary};
  & > div {
    flex-grow: 10;
    display: flex;
  }
`;

export { Container };
