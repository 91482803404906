import React, { createContext, useEffect } from "react";
import * as I from "../assets/types";
import { io } from "socket.io-client";

interface Props {
  children: JSX.Element;
}

const SocketContext = createContext<I.SocketContext | null>(null);

const SocketContextProvider = (props: Props) => {
  const { children } = props;

  const url = "wss://queues.tkeventssrl.store:8080/queues";
  const socket = io(url, { autoConnect: false, transports: ["websocket"],
          extraHeaders: {
                "Access-Control-Allow-Origin": "*",
                    },});

  //states:

  //component life cycle:
  useEffect(() => {
    socket.connect();
    // eslint-disable-next-line
  }, []);

  return (
    <SocketContext.Provider value={{ socket }}>
      {children}
    </SocketContext.Provider>
  );
};

export { SocketContext, SocketContextProvider };
