import styled from "@emotion/styled";
import { Box } from "@mui/material";

interface Props {}

const Container = styled(Box)<Props>`
  & :nth-last-of-type(1) {
    /* color: rgb(144, 149, 168); */
  }
`;

export { Container };
