import styled from "@emotion/styled";
import { Dialog } from "@mui/material";
import * as T from "../../assets/themes";

interface Props {}

const Container = styled(Dialog)<Props>`
  & .MuiDialog-paper {
    background: ${T.colors.nonary};

    text-shadow: 0 -1px 0 rgba(var(--color-shadow), 0.08),
      0 1px 0 rgba(var(--color-light), 1);

    box-shadow: inset -1px -1px 1px rgba(var(--color-shadow), 0.06),
      2px 2px 2px rgba(var(--color-shadow), 0.08),
      4px 4px 6px rgba(var(--color-shadow), 0.08),
      8px 8px 16px rgba(var(--color-shadow), 0.08),
      12px 12px 24px rgba(var(--color-shadow), 0.08);
  }
  & .MuiDialogTitle-root {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  & .MuiDialogContent-root {
    overflow: hidden hidden;
    display: flex;
    padding: 10px;
  }

  & .MuiDialogActions-root {
    & > button {
      border: 0;
      width: 15%;
      ${T.upBoxStyles}

      &:hover {
        color: var(--brand-color_2);

        text-shadow: 0 -1px 0 rgba(var(--color-shadow), 0.08),
          0 1px 0 rgba(var(--color-light), 1),
          0 0 1px rgba(var(--color-light), 0.7),
          0 0 4px rgba(var(--color-glow), 0.08),
          0 0 8px rgba(var(--color-glow), 0.08);

        box-shadow: inset 1px 1px 1px rgba(var(--color-light), 1),
          -3px -3px 3px rgba(var(--color-light), 1),
          -5px -5px 6px rgba(var(--color-light), 1),
          -10px -10px 16px rgba(var(--color-light), 1),
          -14px -14px 24px rgba(var(--color-light), 1),
          inset -1px -1px 1px rgba(var(--color-shadow), 0.06),
          3px 3px 3px rgba(var(--color-shadow), 0.085),
          5px 5px 6px rgba(var(--color-shadow), 0.085),
          10px 10px 16px rgba(var(--color-shadow), 0.085),
          14px 14px 24px rgba(var(--color-shadow), 0.085);
      }
      &:active {
        box-shadow: 1px 1px 1px rgba(var(--color-light), 1),
          inset -2px -2px 2px rgba(var(--color-light), 0.9),
          inset -3px -3px 4px rgba(var(--color-light), 0.9),
          inset -6px -6px 12px rgba(var(--color-light), 0.9),
          inset -8px -8px 16px rgba(var(--color-light), 0.9),
          -1px -1px 1px rgba(var(--color-shadow), 0.06),
          inset 2px 2px 2px rgba(var(--color-shadow), 0.08),
          inset 3px 3px 4px rgba(var(--color-shadow), 0.08),
          inset 6px 6px 12px rgba(var(--color-shadow), 0.08),
          inset 8px 8px 16px rgba(var(--color-shadow), 0.08);
      }
    }
  }
`;

export { Container };
