import React, { createContext, useState } from "react";
import * as I from "../assets/types";
import Dialog from "../widgets/Dialog/Dialog";

interface Props {
  children: JSX.Element | JSX.Element[];
}

const DialogContext = createContext<I.DialogContext | null>(null);

const DialogContextProvider = (props: Props) => {
  const { children } = props;
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState<string | undefined>();
  const [content, setContent] = useState<JSX.Element | undefined>();
  const [labelPrimaryButton, setLabelPrimaryButton] = useState<
    string | undefined
  >("Ok");
  const [labelSecondaryButton, setLabelSecondaryButton] = useState<
    string | undefined
  >();
  const [handleConfirm, setHandleConfirm] = useState(() => () => handleClose());

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setTitle(undefined);
    setContent(undefined);
    setHandleConfirm(() => () => handleClose());
  };

  const handleView = (show: {
    title: string;
    content: JSX.Element;
    primaryButton: string;
    onConfirm?: () => void;
    secondaryButton?: string;
  }) => {
    setTitle(show.title);
    setContent(show.content);
    setLabelPrimaryButton(show.primaryButton);

    if (show.onConfirm) {
      setHandleConfirm(() => () => {
        show.onConfirm?.();
        handleClose();
      });
    }

    if (show.secondaryButton) {
      setLabelSecondaryButton(show.secondaryButton);
    }
  };

  return (
    <DialogContext.Provider
      value={{
        open,
        title,
        content,
        handleOpen,
        handleClose,
        handleConfirm,
        labelPrimaryButton,
        labelSecondaryButton,
        handleView,
      }}
    >
      <Dialog />
      {children}
    </DialogContext.Provider>
  );
};

export { DialogContext, DialogContextProvider };
