import React from "react";
import * as S from "./StyledNotificationWidget";
import { IconButton, Badge, Tooltip } from "@mui/material";
import { Notifications as NotificationsIcon } from "@mui/icons-material";

interface Props {}

const Component = (props: Props) => {
  return (
    <S.Container>
      <Tooltip title="Notifications">
        <IconButton
          size="large"
          aria-label="show 17 new notifications"
          color="inherit"
        >
          <Badge badgeContent={6} color="success">
            <NotificationsIcon />
          </Badge>
        </IconButton>
      </Tooltip>
    </S.Container>
  );
};

export default Component;
