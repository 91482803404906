import React from "react";
import * as S from "./StyledMenuWidget";
import { IconButton } from "@mui/material";
import { Menu as MenuIcon } from "@mui/icons-material";

interface Props {
  handleClick?: () => void;
}

const Component = (props: Props) => {
  return (
    <S.Container onClick={props.handleClick}>
      <IconButton>
        <MenuIcon />
      </IconButton>
    </S.Container>
  );
};

export default Component;
