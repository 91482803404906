import React, { useState, MouseEvent } from "react";
import * as S from "./StyledTaskWidget";
import { IconButton, Tooltip } from "@mui/material";
import { Widgets as WidgetsIcon } from "@mui/icons-material";

interface Props {
  tasks?: { name: string; description: string; status: string }[];
}

const Component = (props: Props) => {
  const [open, setOpen] = useState(false);

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setOpen((prevOpen) => !prevOpen);
  };

  const id = open ? "tasks-in-progress-popper" : undefined;

  return (
    <S.Container>
      <Tooltip title="Events">
        <IconButton aria-describedby={id} onClick={handleClick}>
          <WidgetsIcon />
        </IconButton>
      </Tooltip>
    </S.Container>
  );
};

export default Component;
