import React from "react";
import * as S from "./StyledModule";
import { Radio, Typography } from "@mui/material";
import { Modules } from "../../assets/types";

interface Props {
  module: Modules;
  handleClick: (arg0: string) => void;
}

const Component = (props: Props) => {
  const { module, handleClick } = props;

  return (
    <S.Container
      className={module.name}
      onClick={() => handleClick(module.name)}
    >
      <Radio name="modules" value={module.name} id={module.name} />
      <S.Label htmlFor={module.name}>
        {module.icon}
        <Typography>{module.description}</Typography>
      </S.Label>
    </S.Container>
  );
};

export default Component;
