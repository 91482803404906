import styled from "@emotion/styled";
import { Box } from "@mui/material";
import * as T from "../../assets/themes";

interface Props {}
// const Container = styled(Box)<Props>`
//   height: 100%;
//   width: 100%;
//   padding: 10px;
//   border-radius: 4px;
//   overflow: hidden auto;
//   ${T.scrollStyles};
// `;

const Container = styled(Box)<Props>`
  height: 100%;
  width: 100%;
  border-radius: 0.8rem;
`;

const Wrapper = styled(Box)<Props>`
  padding: 10px;
  height: 100%;
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.05) 0rem 1.25rem 1.6875rem 0rem;
  border-radius: 0.8rem;
`;

const Header = styled(Box)<Props>`
  height: 10%;
  display: flex;
  align-items: center;

  & .MuiTextField-root {
    margin: 0px 20px;
    width: 30%;
  }

  & .MuiInputBase-root {
    ${T.downBoxStyles}

    & fieldset {
      border: 0;
    }
  }
`;

export { Container, Wrapper, Header };
