import styled from "@emotion/styled";

interface Props {
  openDrawer?: boolean;
  smDisplay: boolean;
}

const handleDisplay = (props: Props) => {
  return props.openDrawer && props.smDisplay
    ? `display: none`
    : `display: flex`;
};

const handlePaddingLeft = (props: Props) => {
  return props.openDrawer && `padding-left: 13vw`;
};

const Container = styled.main<Props>`
  ${(props) => handleDisplay(props)};
  ${(props) => handlePaddingLeft(props)};
  flex-grow: 10;
  height: 90vh;
  padding: 10px;
  transition: padding-left 0.3s;
  width: auto
  overflow: hidden;
  flex-direction: column;

  & > div {
    //border: solid;
  }
`;

export { Container };
