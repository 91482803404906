import React, { useContext } from "react";
import { AppContext } from "../../contexts/AppContext";
//import { SnackBarContext } from "../../contexts/SnackBarContext";
import * as S from "./StyledNavBar";
//import * as I from "../../assets/types";
import MenuWidget from "../../widgets/MenuWidget/MenuWidget";
import { Box, Typography } from "@mui/material";
import AccountWidget from "../../widgets/AccountWidget/AccountWidget";
import NotificationWidget from "../../widgets/NotificationWidget/NotificationWidget";
import MoreWidget from "../../widgets/MoreWidget/MoreWidget";
import TaskWidget from "../../widgets/TaskWidget/TaskWidget";

interface Props {
  handleNavigate: (path?: string) => void;
}

const Component = (props: Props) => {
  const { handleNavigate } = props;
  //context:
  const appCtx = useContext(AppContext);
  //const snackCtx = useContext(SnackBarContext);

  const changeModule = (moduleName?: string) => {
    appCtx?.closeDrawer();
    handleNavigate?.(moduleName);
  };

  return (
    <S.Container>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          mr: "auto",
          flexGrow: 1,
        }}
      >
        <MenuWidget handleClick={appCtx?.handleDrawer} />
        <S.Brand
          onClick={() => {
            changeModule();
          }}
        >
          <Typography variant="h5">seatx</Typography>
          <Typography variant="h5" sx={{ marginLeft: 1 }}>
            panel
          </Typography>
        </S.Brand>
      </Box>
      <Box sx={{ flexGrow: 1, display: "flex", justifyContent: "flex-end" }}>
        <Box sx={{ display: { xs: "none", md: "flex" } }}>
          <TaskWidget />
          <NotificationWidget />
          <AccountWidget />
        </Box>
        <Box sx={{ display: { xs: "flex", md: "none" } }}>
          <MoreWidget />
        </Box>
      </Box>
    </S.Container>
  );
};

export default Component;
