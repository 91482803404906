import styled from "@emotion/styled";
import { Box } from "@mui/material";

interface Props {}

const Container = styled(Box)<Props>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 10px;
  height: 100%;
`;
export { Container };
