import React from "react";
import * as S from "./StyledWebsiteUnderConstruction";
import { Typography } from "@mui/material";

interface Props {
  pageName: string;
}

const Component = (props: Props) => {
  const { pageName } = props;
  return (
    <S.Container>
      <Typography>coming soon</Typography>
      <Typography>{pageName} page is under construction</Typography>
    </S.Container>
  );
};

export default Component;
