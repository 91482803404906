import React, { useContext } from "react";
import * as S from "./StyledDialog";
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from "@mui/material";
import { DialogContext } from "../../contexts/DialogContext";

interface Props {}

const Component = (props: Props) => {
  const dlgCtx = useContext(DialogContext);

  return (
    <S.Container
      open={dlgCtx?.open || false}
      onClose={(event, reason) => {
        return reason !== "backdropClick" && "escapeKeyDown";
      }}
      maxWidth={"lg"}
    >
      <DialogTitle>
        <Typography variant="h5" component={"p"}>
          {dlgCtx?.title}
        </Typography>
      </DialogTitle>
      <DialogContent>{dlgCtx?.content}</DialogContent>
      <DialogActions>
        {dlgCtx?.labelSecondaryButton ? (
          <>
            <Button onClick={dlgCtx?.handleClose} variant="outlined">
              {dlgCtx?.labelSecondaryButton}
            </Button>
            <Button
              onClick={dlgCtx?.handleConfirm}
              variant="outlined"
              size="large"
              //color="success"
              autoFocus
            >
              {dlgCtx?.labelPrimaryButton}
            </Button>
          </>
        ) : (
          <Button
            onClick={dlgCtx?.handleConfirm}
            variant="outlined"
            size="large"
            //color="success"
            autoFocus
          >
            {dlgCtx?.labelPrimaryButton}
          </Button>
        )}
      </DialogActions>
    </S.Container>
  );
};

export default Component;
