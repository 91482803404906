import React, { useContext } from "react";
import { AppContext } from "../../contexts/AppContext";
import * as S from "./StyledSideBar";
import { Fade, Box, Divider } from "@mui/material";
import Module from "../Module/Module";
import { Modules } from "../../assets/types";

interface Props {
  module: string | undefined;
  modules: Modules[] | undefined;
  handleNavigate: (path?: string) => void;
}

const Component = (props: Props) => {
  const { modules, handleNavigate } = props;
  //context:
  const appCtx = useContext(AppContext);

  const changeModule = (moduleName?: string) => {
    appCtx?.closeDrawer();
    handleNavigate?.(moduleName);
  };

  return (
    <S.Container openDrawer={appCtx?.openDrawer}>
      <Fade in={appCtx?.openDrawer} timeout={1000}>
        <S.Wrapper>
          <S.Group selected={appCtx?.activeModule}>
            <Box>
              <Box>
                {modules?.map((module, i) =>
                  module.moduleType === "main" ? (
                    <Module
                      key={i}
                      module={module}
                      handleClick={changeModule}
                    />
                  ) : (
                    false
                  ),
                )}
              </Box>
              <Divider sx={{ margin: "10px" }} />
              <Box>
                {modules?.map((module, i) =>
                  module.moduleType === "auxiliary" ? (
                    <Module
                      key={i}
                      module={module}
                      handleClick={changeModule}
                    />
                  ) : (
                    false
                  ),
                )}
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-end",
              }}
            >
              {modules?.map((module, i) =>
                module.moduleType === "setting" ? (
                  <Module key={i} module={module} handleClick={changeModule} />
                ) : (
                  false
                ),
              )}
            </Box>
          </S.Group>
        </S.Wrapper>
      </Fade>
    </S.Container>
  );
};

export default Component;
