import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  ReactNode,
} from "react";
import axios from "axios";
import https from "https";
// import { SnackBarContext } from "./SnackBarContext";
// import { DialogContext } from "./DialogContext";
import { SocketContext } from "./SocketContext";
import * as I from "../assets/types";
import * as T from "../assets/themes";
import {
  Dashboard as DashboardIcon,
  CalendarMonth as CalendarMonthIcon,
  RocketLaunch as UniverseIcon,
  ShoppingBag as ShoppingBagIcon,
  LocalActivity as LocalActivityIcon,
  Settings as SettingsIcon,
} from "@mui/icons-material";

//interfaces:
interface Props {
  children: JSX.Element | ReactNode;
}

//constants:

const API_URL = process.env.API_URL || "https://queues.tkeventssrl.store:8080";

const defaultSettings = {
  authenticated: true,
  languaje: "es",
  theme: "light",
};

const defaultModules: I.Modules[] = [
  {
    moduleType: "main",
    name: "dashboards",
    description: "Dashboard",
    icon: <DashboardIcon />,
    styles: T.mainStyles,
  },
  {
    moduleType: "main",
    name: "sessions",
    description: "Sessions",
    icon: <CalendarMonthIcon />,
    styles: T.cardViewBoxStyles,
  },
  {
    moduleType: "auxiliary",
    name: "queues",
    description: "Queues",
    icon: <UniverseIcon />,
    styles: T.cardViewBoxStyles,
  },
  {
    moduleType: "auxiliary",
    name: "carts",
    description: "Carts",
    icon: <ShoppingBagIcon />,
    styles: T.cardViewBoxStyles,
  },
  {
    moduleType: "auxiliary",
    name: "tickets",
    description: "Tickets",
    icon: <LocalActivityIcon />,
    styles: T.cardViewBoxStyles,
  },
  {
    moduleType: "setting",
    name: "settings",
    description: "Settings",
    icon: <SettingsIcon />,
    styles: T.cardViewBoxStyles,
  },
];

const AppContext = createContext<I.AppContext | null>(null);

const AppContextProvider = (props: Props) => {
  const { children } = props;
  //const snackCtx = useContext(SnackBarContext);
  //const dlgCtx = useContext(DialogContext);
  const socketCtx = useContext(SocketContext);

  //states:
  const [settings] = useState<I.Settings>(defaultSettings);
  const [availableModules] = useState<I.Modules[]>(defaultModules);
  const [activeModule, setActiveModule] = useState<string>();
  const [moduleSettings, setModuleSettings] = useState<I.Modules>();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [cardProps] = useState<{ [key: string]: any }>();
  const [availableEvents, setAvailableEvents] = useState<I.Events[]>([]);
  const [availableSessions, setAvailableSessions] = useState<I.Sessions[]>([]);
  const [availableQueues, setAvailableQueues] = useState<I.Queues[]>([]);
  const [selectedSession, setSelectedSession] = useState<
    I.Sessions | undefined
  >();
  const [selectedEvent, setSelectedEvent] = useState<I.Events | undefined>();
  const [sessionQueues, setSessionQueues] = useState<I.Queues[] | undefined>();
  const [eventSessions, setEventSessions] = useState<
    I.Sessions[] | undefined
  >();
  const [queueStatusNotification, setQueueStatusNotification] = useState<{
    queue: string;
  }>();

  //custom-type-validators:
  function isAPIResponseError(data: any): data is I.APIResponseErrors {
    return data && data.status === "error" && true;
  }

  //to destructe documents and get _id:
  // function getDocId(doc: I.Queues | I.Mutations | I.Exceptions) {
  //   let obj: { [key: string]: any } = {};

  //   Object.entries(doc).forEach(([key, value]) => {
  //     obj[key] = value;
  //   });

  //   return obj["_id"];
  // }

  //handlers:
  function changeActiveModule(module: string | undefined) {
    setActiveModule(module);
  }

  function handleDrawer() {
    return openDrawer ? setOpenDrawer(false) : setOpenDrawer(true);
  }

  function closeDrawer() {
    return setOpenDrawer(false);
  }

  const deleteQueue = (queue: I.Queues) => {
    let arr: I.Queues[] = availableQueues || [];
    let data = arr.find((row) => row === queue);
    if (data !== undefined) {
      let i = arr.indexOf(data);
      if (i !== -1) {
        arr.splice(i, 1);
        setAvailableQueues(arr);
      }
    }
  };

  function changeSelectedSession(sessionDescription?: string) {
    if (sessionDescription) {
      let session = availableSessions?.find(
        (obj) => obj.description === sessionDescription,
      );
      session && setSelectedSession(session);
    } else {
      setSelectedSession(undefined);
    }
  }

  function changeSelectedEvent(eventDescription?: string) {
    if (eventDescription) {
      let event = availableEvents?.find(
        (obj) => obj.description === eventDescription,
      );
      event && setSelectedEvent(event);
    } else {
      setSelectedEvent(undefined);
    }
  }

  async function updateQueueStatus(queue: string, status: I.queueStatusTypes) {
    try {
      let config = {
        method: "put",
        url: `${API_URL}/queues?name=${queue}`,
        data: { status: status },
        httpsAgent: new https.Agent({
          rejectUnauthorized: false,
        }),
      };
      let res = await axios(config);
      return res;
    } catch (error) {
      console.error(error);
    }
  }

  //loads:
  async function getModuleSettings() {
    try {
      let data = availableModules.find(
        (record) => record.name === activeModule || "dashboards",
      );
      return data;
    } catch (error) {
      console.error(error);
    }
  }

  async function getEvents() {
    let config = {
      method: "get",
      url: `${API_URL}/events`,
      httpsAgent: new https.Agent({
        rejectUnauthorized: false,
      }),
    };
    try {
      let res = await axios(config);
      let data = await res.data;
      return data;
    } catch (error) {
      console.error(error);
    }
  }

  async function getSessions() {
    let config = {
      method: "get",
      url: `${API_URL}/sessions`,
      httpsAgent: new https.Agent({
        rejectUnauthorized: false,
      }),
    };
    try {
      let res = await axios(config);
      let data = await res.data;
      return data;
    } catch (error) {
      console.error(error);
    }
  }

  async function getQueues(status: string) {
    let config = {
      method: "get",
      url: `${API_URL}/queues?status=${status}`,
      httpsAgent: new https.Agent({
        rejectUnauthorized: false,
      }),
    };

    try {
      let res = await axios(config);
      let data = await res.data;
      return data;
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    getModuleSettings().then((res) => {
      setModuleSettings(res);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeModule]);

  useEffect(() => {
    getEvents().then((res) => {
      return isAPIResponseError(res)
        ? setAvailableEvents([])
        : setAvailableEvents(res);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getSessions().then((res) => {
      return isAPIResponseError(res)
        ? setAvailableSessions([])
        : setAvailableSessions(res);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    async function getAvailableQueues() {
      let data: I.Queues[] = [];
      await getQueues("IN_PROGRESS").then((res) => {
        return (
          !isAPIResponseError(res) && res.map((ele: I.Queues) => data.push(ele))
        );
      });

      await getQueues("IN_EXCEPTION").then((res) => {
        return (
          !isAPIResponseError(res) && res.map((ele: I.Queues) => data.push(ele))
        );
      });

      setAvailableQueues(data);
    }
    getAvailableQueues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queueStatusNotification]);

  useEffect(() => {
    async function getSessionQueues() {
      if (selectedSession) {
        let queues = availableQueues?.filter(
          (obj) => obj.session === selectedSession.name,
        );
        setSessionQueues(queues);
      } else {
        setSessionQueues(availableQueues);
      }
    }
    getSessionQueues();
    // eslint-disable-next-line
  }, [availableQueues, selectedSession, availableSessions]);

  useEffect(() => {
    async function getEventSessions() {
      if (selectedEvent) {
        let sessions = availableSessions?.filter(
          (obj) => obj.event === selectedEvent.name,
        );
        setEventSessions(sessions);
      } else {
        setEventSessions(availableSessions);
      }
    }
    getEventSessions();
    // eslint-disable-next-line
  }, [selectedEvent, availableEvents]);

  useEffect(() => {
    socketCtx?.socket.on("ADDED_QUEUE", (socket) =>
      setQueueStatusNotification(socket),
    );
    // eslint-disable-next-line
  }, [socketCtx]);

  useEffect(() => {
    socketCtx?.socket.on("UPDATED_QUEUE", (socket) =>
      setQueueStatusNotification(socket),
    );
    // eslint-disable-next-line
  }, [socketCtx]);

  return (
    <AppContext.Provider
      value={{
        activeModule,
        availableModules,
        cardProps,
        changeActiveModule,
        getModuleSettings,
        handleDrawer,
        closeDrawer,
        moduleSettings,
        openDrawer,
        settings,
        availableEvents,
        availableSessions,
        availableQueues,
        deleteQueue,
        selectedSession,
        sessionQueues,
        changeSelectedSession,
        selectedEvent,
        eventSessions,
        changeSelectedEvent,
        updateQueueStatus,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export { AppContext, AppContextProvider };
