import styled from "@emotion/styled";
import { Box, RadioGroup } from "@mui/material";
import * as T from "../../assets/themes";

interface Props {
  openDrawer?: boolean;
  selected?: string | undefined;
}

const handleDisplay = (props: Props) => {
  return props.openDrawer ? `display: block` : `display: none`;
};

const Container = styled.nav<Props>`
  ${(props) => handleDisplay(props)};
  height: 90vh;
  width: auto;
  flex-grow: 1;
`;

const Wrapper = styled(Box)<Props>`
  width: 100%;
  height: 100%;
  padding: 0px 10px;
  min-width: 12vw;
  z-index: -10;
  ${T.scrollStyles};
`;

const Group = styled(RadioGroup)<Props>`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  & .${(props) => props.selected} {
    border: solid 2px ${T.colors.denary};
    ...${T.upBoxStyles}
  }
`;

export { Container, Wrapper, Group };
