import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { AppContextProvider } from "./contexts/AppContext";
import { DialogContextProvider } from "./contexts/DialogContext";
import { SnackBarContextProvider } from "./contexts/SnackBarContext";
import { SocketContextProvider } from "./contexts/SocketContext";
import Home from "./containers/Home/Home";
import "./App.css";

import { createTheme, ThemeProvider } from "@mui/material";

const theme = createTheme({
  typography: {
    fontFamily: ["Bebas Neue"].join(","),
    fontSize: 15,
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <SocketContextProvider>
        <SnackBarContextProvider>
          <DialogContextProvider>
            <AppContextProvider>
              <BrowserRouter>
                <Routes>
                  <Route
                    path=""
                    element={<Navigate to={"/dashboards"} />}
                  ></Route>
                  <Route path="/:module" element={<Home />}></Route>
                  <Route path="/:module/:moduleItem" element={<Home />}></Route>
                </Routes>
              </BrowserRouter>
            </AppContextProvider>
          </DialogContextProvider>
        </SnackBarContextProvider>
      </SocketContextProvider>
    </ThemeProvider>
  );
}

export default App;
