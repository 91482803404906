import React, { useContext } from "react";
import { AppContext } from "../../contexts/AppContext";
import * as S from "./StyledSession";
import { Box, Typography, TextField, MenuItem, Fade } from "@mui/material";
import CardWidget from "../../widgets/CardWidget/CardWidget";
import ItemViewer from "../../widgets/ItemViewer/ItemViewer";
//import * as I from "../../assets/types";
import * as T from "../../assets/themes";

interface Props {}

const cardStyles = {
  height: "250px",
  width: "250px",
  margin: "10px",

  "& .MuiPaper-root": {},

  "& .MuiCardMedia-root": {
    height: "50%",
    width: "100%",
    position: "relative",
  },

  "& .MuiCardContent-root": {
    height: "80%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },

  "& .MuiCardActions-root": {},
};

const Component = (props: Props) => {
  const appCtx = useContext(AppContext);

  return (
    <Fade in appear timeout={500}>
      <S.Container>
        <S.Wrapper>
          <S.Header>
            <Typography variant="h6">sessions</Typography>
            <TextField
              variant="outlined"
              id="event-filter"
              aria-label="event filter"
              size="small"
              label="filter by event"
              select
              inputProps={{
                event: appCtx?.selectedEvent?.name || "",
              }}
              value={appCtx?.selectedEvent?.description || ""}
              onChange={(e) => appCtx?.changeSelectedEvent(e.target.value)}
            >
              <MenuItem
                value=""
                sx={{
                  height: "25px",
                  padding: "16px 6px",
                }}
              >
                <span></span>
              </MenuItem>
              {appCtx?.availableEvents?.map((option, key) => (
                <MenuItem key={key} value={option.description}>
                  {option.description}
                </MenuItem>
              ))}
            </TextField>
          </S.Header>
          {appCtx?.eventSessions?.length === 0 ? (
            <ItemViewer styles={T.defaultStyles}>
              <Typography>sessions have not been loaded yet</Typography>
            </ItemViewer>
          ) : (
            <>
              {" "}
              <ItemViewer
                styles={{
                  height: "100%",
                  width: "100%",
                  margin: "auto",
                  display: "flex",
                  flexWrap: "wrap",
                  overflow: "hidden auto",
                  ...T.scrollStyles,
                }}
              >
                {appCtx?.eventSessions?.map((session, name) => (
                  <CardWidget
                    key={name}
                    _id={session.name}
                    variant="elevation"
                    styles={{
                      ...cardStyles,
                      ...T.upBoxStyles,
                    }}
                    {...appCtx?.cardProps}
                    content={
                      <>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            marginTop: "5px",
                          }}
                        ></Box>
                      </>
                    }
                    media={{
                      element: (
                        <>
                          <Box
                            component="img"
                            sx={{
                              height: "100px",
                              width: "100px",
                            }}
                            src="/images/events/laliga.png"
                          ></Box>
                        </>
                      ),
                    }}
                  />
                ))}
              </ItemViewer>{" "}
            </>
          )}
        </S.Wrapper>
      </S.Container>
    </Fade>
  );
};

export default Component;
