import React from "react";
import * as S from "./StyledAccountWidget";
import { IconButton, Tooltip } from "@mui/material";
import { AccountCircle as AccountCircleIcon } from "@mui/icons-material";

interface Props {}

const Component = (props: Props) => {
  return (
    <S.Container>
      <Tooltip title="Account">
        <IconButton>
          <AccountCircleIcon />
        </IconButton>
      </Tooltip>
    </S.Container>
  );
};

export default Component;
