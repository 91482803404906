import styled from "@emotion/styled";
import { Box } from "@mui/material";
import * as T from "../../assets/themes";

interface Props {}

const Container = styled.header<Props>`
  display: flex;
  align-items: center;
  height: 10vh;

  position: sticky;
  top: 0;
  z-index: 20;
  min-height: 10vh;
  padding: 0px 20px;
  // background-color: ${T.colors.octonary};
  // ${T.upBoxStyles}
`;

const Brand = styled(Box)<Props>`
  display: flex;
  cursor: pointer;
  margin-left: 100px;
  font-weight: bold;
  text-decoration: none;
  // font-family: "Bebas Neue", cursive;
`;

export { Container, Brand };
