import styled from "@emotion/styled";
import { Box, TableContainer as TableContainerMUI } from "@mui/material";
import * as T from "../../assets/themes";

interface Props {}
const Container = styled(Box)<Props>`
  height: 100%;
  width: 100%;
  overflow: hidden auto;
  border-radius: 0.8rem;
  ${T.scrollStyles};
`;

const Wrapper = styled(Box)<Props>`
  padding: 10px;
  height: 100%;
  overflow-x: auto;
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.05) 0rem 1.25rem 1.6875rem 0rem;
  border-radius: 0.8rem;
`;

const Header = styled(Box)<Props>`
  height: 10%;
  display: flex;
  align-items: center;

  & .MuiTextField-root {
    margin: 0px 20px;
    width: 30%;
  }

  & .MuiInputBase-root {
    ${T.downBoxStyles}

    & fieldset {
      border: 0;
    }
  }
`;

const TableContainer = styled(TableContainerMUI)<Props>`
  height: 80%;
  overflow: hidden auto;
  ${T.scrollStyles}

  & h5,
  h6 {
    text-shadow: 0 -1px 0 rgba(var(--color-shadow), 0.08),
      0 1px 0 rgba(var(--color-light), 1);
    font-size: calc(1em + 0.5vw);
  }

  & .MuiChip-root {
    ${T.upBoxStyles}
  }

  & .MuiTableCell-root {
    & > button {
      width: 1em;
      height: 1em;
      display: inline-block;
      fill: currentcolor;
      flex-shrink: 0;
      transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      font-size: 1.5rem;
      border-radius: 4px;
      box-sizing: content-box;
      color: rgb(255, 255, 255);
    }
  }
`;

export { Container, Wrapper, Header, TableContainer };
