import styled from "@emotion/styled";
import { Box } from "@mui/material";
import * as T from "../../assets/themes";

interface Props {}
const Container = styled(Box)<Props>`
  height: 100%;
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  overflow: hidden auto;
  ${T.scrollStyles};
`;

export { Container };
