const colors = {
  primary: "var(--brand-color_1)",
  secondary: "var(--brand-color_2)",
  tertiary: "var(--brand-color_3)",
  quaternary: "",
  pentarius: "var(--brand-color_5)",
  senary: "var(--brand-color_6)",
  septenary: "var(--brand-color_7)",
  octonary: "var(--brand-color_8)",
  nonary: "var(--brand-color_9)",
  denary: "var(--brand-color_10)",
};

const upBoxStyles = {
  //background: colors.nonary,

  boxShadow:
    "inset 1px 1px 1px rgba(var(--color-light), 0.6), inset -1px -1px 1px rgba(var(--color-shadow), 0.06), -1px -1px 4px 0px rgba(var(--color-light), 0.6), -2px -2px 8px 0px rgba(var(--color-light), 0.6), 1px 1px 4px 0px rgba(var(--color-shadow), 0.06), 2px 2px 8px 0px rgba(var(--color-shadow), 0.06)",

  transition: "color 0.3s, text-shadow 0.3s, box-shadow 0.6s",

  textShadow:
    "0 -1px 0 rgba(var(--color-shadow), 0.08), 0 1px 0 rgba(var(--color-light), 1)",
};

const downBoxStyles = {
  background: colors.denary,

  boxShadow:
    "inset 1px 1px 1px rgba(var(--color-light), 0.4), inset -1px -1px 1px rgba(var(--color-shadow), 0.04), inset 0 0 0 2px var(--brand-color_9), inset -2px -2px 2px 2px rgba(var(--color-light), 0.4), inset -4px -4px 4px 2px rgba(var(--color-light), 0.4), -1px -1px 4px 0px rgba(var(--color-light), 0.4), -2px -2px 8px 0px rgba(var(--color-light), 0.4), inset 2px 2px 2px 2px rgba(var(--color-shadow), 0.04), inset 4px 4px 4px 2px rgba(var(--color-shadow), 0.04), 1px 1px 4px 0px rgba(var(--color-shadow), 0.04), 2px 2px 8px 0px rgba(var(--color-shadow), 0.04)",

  transition: "color 0.3s, text-shadow 0.3s, box-shadow 0.6s",

  textShadow:
    "0 -1px 0 rgba(var(--color-shadow), 0.08), 0 1px 0 rgba(var(--color-light), 1)",
};

const buttonStyles1 = {
  textShadow:
    "0 -1px 0 rgba(var(--color-shadow), 0.08),0 1px 0 rgba(var(--color-light), 1)",

  boxShadow:
    "inset -1px -1px 1px rgba(var(--color-shadow), 0.06), 2px 2px 2px rgba(var(--color-shadow), 0.08), 4px 4px 6px rgba(var(--color-shadow), 0.08), 8px 8px 16px rgba(var(--color-shadow), 0.08), 12px 12px 24px rgba(var(--color-shadow), 0.08)",

  transition: "color 0.3s, text-shadow 0.3s, box-shadow 0.6s",

  "& :hover": {
    color: "var(--brand-color_2)",

    textShadow:
      "0 -1px 0 rgba(var(--color-shadow), 0.08), 0 1px 0 rgba(var(--color-light), 1), 0 0 1px rgba(var(--color-light), 0.7), 0 0 4px rgba(var(--color-glow), 0.08), 0 0 8px rgba(var(--color-glow), 0.08)",

    boxShadow:
      "inset 1px 1px 1px rgba(var(--color-light), 1), -3px -3px 3px rgba(var(--color-light), 1), -5px -5px 6px rgba(var(--color-light), 1), -10px -10px 16px rgba(var(--color-light), 1), -14px -14px 24px rgba(var(--color-light), 1), inset -1px -1px 1px rgba(var(--color-shadow), 0.06), 3px 3px 3px rgba(var(--color-shadow), 0.085), 5px 5px 6px rgba(var(--color-shadow), 0.085), 10px 10px 16px rgba(var(--color-shadow), 0.085), 14px 14px 24px rgba(var(--color-shadow), 0.085)",
  },

  "& :active": {
    boxShadow:
      "1px 1px 1px rgba(var(--color-light), 1), inset -2px -2px 2px rgba(var(--color-light), 0.9), inset -3px -3px 4px rgba(var(--color-light), 0.9), inset -6px -6px 12px rgba(var(--color-light), 0.9), inset -8px -8px 16px rgba(var(--color-light), 0.9), -1px -1px 1px rgba(var(--color-shadow), 0.06), inset 2px 2px 2px rgba(var(--color-shadow), 0.08), inset 3px 3px 4px rgba(var(--color-shadow), 0.08), inset 6px 6px 12px rgba(var(--color-shadow), 0.08), inset 8px 8px 16px rgba(var(--color-shadow), 0.08)",
  },
};

const buttonStyles2 = {
  ...upBoxStyles,

  "& :hover": {
    color: "var(--brand-color_2)",
    border: 0,
    textShadow:
      "0 -1px 0 rgba(var(--color-shadow), 0.08), 0 1px 0 rgba(var(--color-light), 1), 0 0 1px rgba(var(--color-light), 0.7), 0 0 4px rgba(var(--color-glow), 0.08), 0 0 8px rgba(var(--color-glow), 0.08)",

    boxShadow:
      "inset 1px 1px 1px rgba(var(--color-light), 1), -3px -3px 3px rgba(var(--color-light), 1), -5px -5px 6px rgba(var(--color-light), 1), -10px -10px 16px rgba(var(--color-light), 1), -14px -14px 24px rgba(var(--color-light), 1), inset -1px -1px 1px rgba(var(--color-shadow), 0.06), 3px 3px 3px rgba(var(--color-shadow), 0.085), 5px 5px 6px rgba(var(--color-shadow), 0.085), 10px 10px 16px rgba(var(--color-shadow), 0.085), 14px 14px 24px rgba(var(--color-shadow), 0.085)",
  },

  "& :active": {
    boxShadow:
      "1px 1px 1px rgba(var(--color-light), 1), inset -2px -2px 2px rgba(var(--color-light), 0.9), inset -3px -3px 4px rgba(var(--color-light), 0.9), inset -6px -6px 12px rgba(var(--color-light), 0.9), inset -8px -8px 16px rgba(var(--color-light), 0.9), -1px -1px 1px rgba(var(--color-shadow), 0.06), inset 2px 2px 2px rgba(var(--color-shadow), 0.08), inset 3px 3px 4px rgba(var(--color-shadow), 0.08), inset 6px 6px 12px rgba(var(--color-shadow), 0.08), inset 8px 8px 16px rgba(var(--color-shadow), 0.08)",
  },
};

const scrollStyles = {
  " &::-webkit-scrollbar": { width: "14px" },
  " &::-webkit-scrollbar-track": {
    borderRadius: "100px",
    background: colors.nonary,
    boxShadow:
      "inset 1px 1px 1px rgba(var(--color-light), 0.4),inset -1px -1px 1px rgba(var(--color-shadow), 0.04),inset 0 0 0 2px var(--brand-color_9),inset -2px -2px 2px 2px rgba(var(--color-light), 0.4),inset -4px -4px 4px 2px rgba(var(--color-light), 0.4),-1px -1px 4px 0px rgba(var(--color-light), 0.4),-2px -2px 8px 0px rgba(var(--color-light), 0.4),inset 2px 2px 2px 2px rgba(var(--color-shadow), 0.04),inset 4px 4px 4px 2px rgba(var(--color-shadow), 0.04),1px 1px 4px 0px rgba(var(--color-shadow), 0.04),2px 2px 8px 0px rgba(var(--color-shadow), 0.04)",
  },
  " &::-webkit-scrollbar-thumb": {
    ...upBoxStyles,
    borderRadius: "100px",
    background: colors.tertiary,
  },
};

const defaultStyles = {
  padding: "10px",
  height: "100%",
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const modifierBoxStyles = {
  padding: "10px",
  height: "100%",
  width: "100%",
  margin: "auto",
  display: "flex",
  flexWrap: "wrap",
  // gap: "0.5rem",
  // gridAutoRows: "4rem",
  // gridTemplateColumns: "repeat(auto-fill, minmax(10rem, 0.1fr))",
  // "& .MuiPaper-root:hover": {
  //   border: "solid 3px rgb(147,185,243)",
  // },

  "& .MuiPaper-root": {
    flex: "250px",
    margin: "5px",
  },
  overflow: "hidden auto",
  ...scrollStyles,
};

const mainStyles = {
  padding: "10px",
  height: "100%",
  overflow: "hidden auto",
  ...scrollStyles,
};

const cardViewBoxStyles = {
  display: "inline-flex",
  flexWrap: "wrap",
  gap: "12px",
  overflow: "hidden auto",
  ...scrollStyles,
};

const cardUseCaseBoxStyles = {
  width: "40vw",
  height: "30vh",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  "& article": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexWrap: "wrap",
    overflow: "hidden auto",
    ...scrollStyles,
    "& div:hover": { border: "solid 1px rgb(147,185,243)" },
  },
};

export {
  colors,
  scrollStyles,
  defaultStyles,
  mainStyles,
  cardViewBoxStyles,
  cardUseCaseBoxStyles,
  modifierBoxStyles,
  upBoxStyles,
  downBoxStyles,
  buttonStyles1,
  buttonStyles2,
};
