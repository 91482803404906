import React, { useState, MouseEvent } from "react";
import * as S from "./StyledMoreWidget";
import { IconButton, Menu, MenuItem } from "@mui/material";
import { MoreVert as MoreIcon } from "@mui/icons-material";

interface Props {
  options?: string[];
}

const ITEM_HEIGHT = 48;

const Component = (props: Props) => {
  const { options } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <S.Container>
      <IconButton
        aria-label="more"
        id="options-button"
        aria-controls={open ? "options-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreIcon />
      </IconButton>
      <Menu
        id="options-menu"
        MenuListProps={{
          "aria-labelledby": "options-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
          },
        }}
      >
        {options?.map((option, key) => (
          <MenuItem key={key} onClick={handleClose}>
            {option}
          </MenuItem>
        ))}
      </Menu>
    </S.Container>
  );
};

export default Component;
